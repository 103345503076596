<template>
  <div>
    <navigation />
    <transition name="flixFadeIn">
      <div :key="component">
        <signInMenu v-if="!login && !component" :callback="setComponent" />
        <component v-bind:is="component" v-if="!login && component" :callback="function(){ setLogin() }" />
        <div class="flix-text-center" v-if="!login && component">
          <backBtn :settings="{block: false}" :onClick="function(){ setComponent(false) }"/>
        </div>
      </div>
    </transition>
    <cookieBanner></cookieBanner>
  </div>
</template>
<script>
export default {
  components: {
    cookieBanner () { return import('@/components/cookieBanner') },
    loginForm () { return import('@/components/signIn/form') },
    ressourcesForm () { return import('@/components/signIn/ressources') },
    registerForm () { return import('@/components/signIn/register') },
    passwordForm () { return import('@/components/signIn/password') },
    navigation () { return import('@/components/frontend/navigation/navigation') },
    signInMenu () { return import('@/components/signIn/menu') }
  },
  props: {},
  data () {
    return {
      login: false,
      getComponent: false,
      component: false
    }
  },
  methods: {
    setComponent (data) {
      this.component = data
    },
    setLogin () {
      if (typeof window.redirect === 'object') {
        this.$router.push(window.redirect)
        delete window.redirect
      } else {
        this.$router.push({ name: 'dashboard' })
      }
    },
    setLang () {
      if (typeof this.$route.params.lang === 'string') {
        if (Object.keys(this.$i18n.messages).includes(this.$route.params.lang)) {
          this.$i18n.locale = this.$route.params.lang
        }
      }
    }
  },
  mounted () {
    this.setLang()
    if (window.localStorage.getItem('flix_login')) {
      this.login = true
      this.setLogin()
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
